import React from 'react';
import App from 'next/app';
import { appWithTranslation } from 'next-i18next';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18NextConfig from '../next-i18next.config';
import { bugsnag } from '../common/config';

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: bugsnag.apiKey,
    plugins: [new BugsnagPluginReact()],
  });
}

function MyApp({ Component, pageProps }) {
  if (process.env.NODE_ENV !== 'development') {
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    return (
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  } else {
    return <Component {...pageProps} />;
  }
}

MyApp.getInitialProps = async (context) => {
  const { ctx } = context;
  let composedInitialProps = {};
  let userData = null;
  let colorSettings = {};
  let userPermissions = [];
  let currentuser = {};
  let currentActiveAccount = {};
  let activeSiteId = null;
  let activeSite = {};

  let tokens = {
    accessToken: null,
    refreshToken: null,
  };
  if (ctx && ctx.res) {
    tokens = ctx.res.tokens && (await ctx.res.tokens);
    userData = ctx.res.userData;
    console.log(ctx.res.currentActiveAccount);
    colorSettings = ctx.res.currentActiveAccount?.ViewSettings || {};
  }

  if (ctx && ctx.req) {
    if (ctx.req.session.passport && ctx.req.session.passport.user) {
      activeSiteId = ctx.req.session.activeSite.id;
      activeSite = ctx.req.session.activeSite;
      currentuser = ctx.req.session.passport.user;
      userPermissions = {
        permissions: ctx.req.session.permissions,
        sitePermissions: ctx.req.session.sitepermissions,
      };
    } else {
      currentuser = null;
    }
    if (ctx.req.session.activeAccount) {
      currentActiveAccount = ctx.req.session.activeAccount;
    } else if (currentuser && currentuser.PrimaryAccount) {
      currentActiveAccount = currentuser.PrimaryAccount;
    } else {
      currentActiveAccount = null;
    }
  }
  const initObj = App.getInitialProps(context);
  const translate = await serverSideTranslations(ctx.req.locale || 'en', ['common'], nextI18NextConfig);
  return {
    ...initObj,
    pageProps: {
      tokens,
      userData,
      colorSettings,
      userPermissions,
      currentuser,
      currentActiveAccount,
      activeSiteId,
      activeSite,
      ...translate,
    },
  };
};
export default appWithTranslation(MyApp, nextI18NextConfig);
